import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApSettingService {


    list_log(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        return axios.post("ap_log/list_log", form);

    }
    




}

    
 

export default new ApSettingService();